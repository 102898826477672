/* globals */
import expose from './expose'
import { throttle } from 'public/src/js/utils/event'

var _window = typeof window === 'undefined' ? global : window

if (!_window.ep) _window.ep = {}
export default class goodsExpose {
  constructor() {
    this.instance = null
    this.prefix = 'j-expose__'
    this.defaultConfig = {
      scrollContainer: _window,
      scrollTopMask: 0,
      scrollBottomMask: 0,
      section: [
        {
          code: 'list',
          container: 'container-list',
          content: 'content-goodsls',
          target: 'target-goods-img',
          averageCotent: true
        }
      ],
      interval: 100,
      wait: 1000,
      targetExposeTime: 1000,
      targetExposeRadio: 0
    }
  }

  initInstance({ config = {}, getExposeData = null } = {}) {
    // check is create instance
    if (!!this.instance) return this.adjustContainerInfo()

    try {
      config = Object.assign({}, this.defaultConfig, config, {
        callback({ list = {} } = {}) {
          if (!getExposeData) return
          Object.keys(list).length > 0 && getExposeData({ list })
        }
      })

      let code = '' // temp variable
      config.section.forEach(item => {
        if (!item['container'] || !item['content'] || !item['target'])
          throw `miss expose item config`
        !code && (code = item.code)
        item['container'] = `${this.prefix}${item['container']}`
        item['content'] = `${this.prefix}${item['content']}`
        item['target'] = `${this.prefix}${item['target']}`
        item['averageCotent'] = !Object.prototype.hasOwnProperty.call(item, 'averageCotent')
          ? true
          : item['averageCotent']
      })
      _window.ep[code] = this.instance = new expose(config)
      this.resizeScrollContainer()
    } catch (e) {
      console.error(e)
    }
  }

  triggerScroll() {
    if (!this.instance || !this.instance?.scrollContainer) return
    this.instance.scrollContainer.dom.trigger('scroll')
  }

  adjustContainerInfo() {
    if (!this.instance) return
    this.instance.getListRectInfo()
    this.triggerScroll()
  }

  adjustScrollMask(topMask) {
    if (!this.instance || !this.instance?.scrollContainer) return
    this.instance.scrollContainer.topMask = topMask
  }

  resizeScrollContainer() {
    this.throttleResizeEvent = throttle.call(this, {
      fun: ({ isWait } = {}) => {
        if (!this.instance || !this.instance?.scrollContainer) return
        this.instance.scrollContainer.dom.off('scroll', this.instance.scrollContainer.event)
        if (!isWait) return
        this.instance.getListRectInfo()
        this.instance.setScrollEvent()
      },
      interval: 100,
      wait: 800
    })
    _window.addEventListener('resize', this.throttleResizeEvent)
  }

  refreshHasBeenExpose() {
    if (!this.instance) return
    this.instance.HasBeenExpose = {}
  }

  destroyInstance() {
    _window.removeEventListener('resize', this.throttleResizeEvent)
    this.throttleResizeEvent = null
    this.instance?.removeScrollEvent()
    this.instance = null
  }
}
